import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/12C/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/12C/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/12C/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/12C/4.jpg';

// import p5 from 'assests/Photos/ClassAssembly/2024/12A/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2024/12A/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2024/12A/7.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';
import Box from '@mui/material/Box';


const ClassAssembly12C2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });



  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/12C/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/12C/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/12C/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/12C/4.webp`;
 
 

  const photos = [
    
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 2,
      cols: 1,
    },
    {
        src: p1,
        source: p1,
        rows: 2,
        cols: 1,
      },
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 1,
    //   },
      

    //   {
    //     src: p7,
    //     source: p7,
    //     rows: 1.5,
    //     cols: 2,
    //   },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Battle of Guts
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 12C Date: 19 June 2024

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 12 C presented their class assembly on the topic ‘Battle of Guts’. It was depicted through a skit featuring a televised debate that highlighted the significance of gut health, particularly in modern society where lifestyle and dietary choices increasingly impact our well-being. The assembly emphasized how maintaining a healthy gut is crucial for overall health, affecting everything from digestion to mental clarity, immune function and even emotional well-being.
 <br></br>
 The presentation underscored the challenges posed by contemporary diets, high in processed foods and sugars, stressing the need for awareness and conscious choices in daily nutrition. The assembly concluded with a pledge to adopt gut-healthy habits, including balanced diets rich in fiber, adequate hydration, incorporation of probiotics and a reduction in the intake of processed foods. Additionally, the students drew meaningful parallels between maintaining gut health and advocating for a holistic approach to well-being that encompasses both physical health and social values. 
        <br></br>
        This engaging and informative presentation left an indelible mark on the young audience, inspiring them to make healthier choices and to appreciate elder’s wisdom of traditional practices, thereby fostering a community of well-rounded and mindful individuals.
        <br></br>

        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Nurture Your Health, Honor Your Heritage”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly12C2024;